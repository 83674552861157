<template>
  <div class="col team_details_wrapper">
    <!-- Main div for permissions -->
    <div>
      <!-- Start Team Line Graph  -->
      <div class="the_main_wrapper_for_charts mb-4" v-if="!loadingValue">
        <div class="is_chart_wrapper">
          <chart-card
            v-if="scores.length > 0"
            :chart-data="teamChart"
            :chart-options="barChart.options"
            :chart-responsive-options="barChart.responsiveOptions"
            class="rounded-xl p-2"
            style="background: #FCFCFD;"
          >
            <template slot="header">
              <div class="d-flex">
                <i class="el-icon el-icon-data-analysis" style="font-size: 30px; margin-right: 7px"></i>
                <h4 class="card-title">{{ localization("Team Line Graph") }}</h4>
              </div>
              <p
                class="text-muted mt-2"
              >See on a graph the areas where team members have similarities and differences.</p>
            </template>
          </chart-card>
          <div class="lines_in_chart avoid">
            <span class="is_text_chart">Avoid</span>
          </div>
          <div class="lines_in_chart needed">
            <span class="is_text_chart">As Needed</span>
          </div>
          <div class="lines_in_chart first">
            <span class="is_text_chart">Use First</span>
          </div>
        </div>
        <div class="for_line_graph_members_container mt-2">
          <div
            v-for="(member, index) in team_members"
            :key="member.id"
            class="every_bullet_wrapper"
            :class="`member_${index + 1}`"
          >
            <span class="bullet"></span>
            <span class="text-capitalize">{{ member.first_name }} {{ member.last_name }}</span>
          </div>
        </div>
        <!-- <div class="description_team w-100">
          <p class="team_type">{{ team_details.classification }}</p>
        </div>-->
        <div class="team_routes_wrapper" :class="{ 'd-flex flex-row-reverse': isRtl() }">
          <router-link class="back_link" to="/teams">
            <i class="fa fa-arrow-left back_icon mr-1"></i>
            {{ localization("Back to team") }}
          </router-link>
          <router-link class="back_link" to="/working-together">
            {{ localization("next") }}
            <i class="fa fa-arrow-right back_icon mr-1"></i>
          </router-link>
        </div>
      </div>
      <!-- End Team Line Graph  -->

      <!-- TWP Section -->
      <div class="rounded-xl mb-4 p-4 shadow-sm twp">
        <h4 class="m-0 my-1">Your Team Work Profile (TWP)</h4>
        <p
          class="text-muted"
        >View, edit, or share your TWP and get accurate strategies for your team tasks.</p>
        <div>
          <el-button class="rounded-lg">
            <img class="img" src="../../../assets/new-theme/eye_secondary.png" alt />
            View your PWP
          </el-button>

          <el-button type="primary" plain class="text-primary border-0 rounded-lg">
            <img class="img" src="../../../assets/new-theme/edit_primary.png" alt />
            Edit your PWP
          </el-button>
        </div>

        <div class="action-twp">
          <div class="d-flex">
            <el-button class="rounded-lg border-0 text-primary m-0 p-1">
              <img class="img" src="../../../assets/new-theme/printer.png" alt />
              Print
            </el-button>
            <el-button class="rounded-lg border-0 text-primary m-0 p-1">
              <img class="img" src="../../../assets/new-theme/share.png" alt />
              Share
            </el-button>
          </div>
        </div>
      </div>

      <!-- Start Add Statements  -->
      <el-dialog
        title="Add Statements"
        :visible.sync="show_add_statements_dialog"
        width="50%"
        top="4vh"
        :close-on-click-modal="false"
      >
        <el-form
          ref="personalStatementsRef"
          :model="user"
          :rules="personalStatementsRules"
          label-position="top"
          @submit.prevent="saveStatements"
        >
          <!-- Sequence  -->
          <el-form-item label="Sequence" label-width="20%" prop="sequence_statement">
            <el-input v-model="user.sequence_statement" />
          </el-form-item>
          <!-- Precision  -->
          <el-form-item label="Precision" label-width="20%" prop="precision_statement">
            <el-input v-model="user.precision_statement" />
          </el-form-item>
          <!-- Technical Reasoning  -->
          <el-form-item
            label="Technical Reasoning"
            label-width="20%"
            prop="technical_reasoning_statement"
          >
            <el-input v-model="user.technical_reasoning_statement" />
          </el-form-item>
          <!-- Confluence  -->
          <el-form-item label="Confluence" label-width="20%" prop="confluence_statement">
            <el-input v-model="user.confluence_statement" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer dialog_footer">
          <el-button @click="close_add_statement_dialog" class="text-center d-block">Cancel</el-button>

          <el-button @click="saveStatements" class="text-center d-block text-capitalize">Save</el-button>
        </div>
      </el-dialog>
      <!-- End Add Statements  -->

      <!-- Team Members  -->
      <div class=" mt-5 ">
        <!-- Table And Data -->
          <div class="main_table">
            <TableHeaderSection
              @toggleModal="ToggleTaskModal('add')"
              title="Team members"
              :badge="team_members.length +' members'" 
              text="See all team members' personal profiles and learning patterns or add new members to your team."
              buttonText="Add new member"
              :total="$store.getters['user/tasks_count']"
            ></TableHeaderSection>
            <!-- Start Main Table Here  padding -->
            <div class="header_container just_custom_table_tasks">
              <CustomTable
                :count="team_members.length"
                :DataItems="team_members"
                @Refresh="refresh"
                @reset="filter = {}"
                :filter="filter"
                :title="'Tasks'"
                hidden_export_import
                admin_permission="admin_tasks"
                edit_permission="add_tasks"
                class="team_members"
              >

                <!-- Tasks Table  -->
                <template #columns>
                  <el-table-column type="selection" width="80"></el-table-column>
                  <el-table-column prop="first_name" sortable :label="`${localization('Member Name')}`">
                    <template slot-scope="props">
                      <div>
                        <span>{{ props.row.first_name }} </span>
                        <span>{{ props.row.last_name }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="sequence" sortable :label="`${localization('sequence')}`">
                    <template slot-scope="props">
                      <span class="text-white bg-info rounded-pill px-4 py-2">{{ props.row.sequence }} - {{ props.row.classification }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="precision" sortable :label="`${localization('Precision')}`">
                    <template slot-scope="props">
                      <span class="text-white bg-success rounded-pill px-4 py-2">{{ props.row.precision }} - {{ props.row.classification }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="technical_reasoning " sortable :label="`${localization('Technical Reasoning ')}`">
                    <template slot-scope="props">
                      <span class="text-white bg-warning rounded-pill px-4 py-2">{{ props.row.technical_reasoning }} - {{ props.row.classification }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="confluence" sortable :label="`${localization('Confluence')}`">
                    <template slot-scope="props">
                      <span class="text-white bg-danger rounded-pill px-4 py-2">{{ props.row.confluence }} - {{ props.row.classification }}</span>
                    </template>
                  </el-table-column>
                  <!-- //? Task Actions  -->
                  <el-table-column prop width="auto">
                    <template slot-scope="scope">
                      <div class="td_actions flex justify-content-end">
                        <!-- show task button  -->
                        <router-link
                          v-if="hasPermission('show_tasks')"
                          :to="`#`"
                          v-tooltip.top-center="
                              `${localization('Show')}`
                            "
                          class="btn btn-info btn-link btn-xs"
                        >
                          <img src="@/assets/new-theme/eye.png" alt="eye" />
                        </router-link>
                        <span class="btn btn-warning btn-simple btn-link"
                          v-tooltip.top-center="
                            `${localization('Delete')}`
                          "
                        >
                          <img src="@/assets/new-theme/trash.png" alt="delete" />
                        </span>
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </CustomTable>
              <!-- <DeleteTasks
                v-if="selected.length > 0"
                :text="`${selected.length} tasks selected`"
                @close="resetSelections"
                @print="printTasks"
                @delete="deleteTasks"
              ></DeleteTasks> -->
            </div>
            <!-- End Main Table Here -->
          </div>
        <!-- End Table And Data -->
      </div>

      <!-- tabs -->
      <div class="mt-5">
        <template>
          <el-tabs type="border-card">
            <el-tab-pane label="Team tasks">
              <TeamTask />
            </el-tab-pane>
            <el-tab-pane label="Bring to a team">Bring to a team</el-tab-pane>
            <el-tab-pane label="Need from a team">Need from a team</el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import { mapGetters } from "vuex";
import ServerTable from "@/components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import { ChartCard, StatsCard } from "src/components/index";
import TeamTask from "./TeamTask.vue";
import Swal from "sweetalert2";
export default {
  name: "TeamDetails",
  data() {
    return {
      activeName: "Team members",
      filter: {},
      options: {
        low: 0,
        showArea: true,
        height: "350px",
        axisX: {
          showGrid: true
        },
        lineSmooth: true,
        showLine: true,
        showPoint: true
        // chartPadding: {
        //   right: 10
        // }
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          // distributeSeries: true,
          // steps: 5,
          low: 7,
          high: 40,
          // lineSmooth: true,

          showLine: true,

          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
          // plugins: [Chartist.plugins.tooltip()]
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      personalStatementsRules: {
        sequence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        precision_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        technical_reasoning_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        confluence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      user: {
        sequence_statement: null,
        precision_statement: null,
        technical_reasoning_statement: null,
        confluence_statement: null
      },
      team_id: null,
      member_id: null,
      pattern_id: null,
      show_add_statements_dialog: false,
      type: null
    };
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"]
    }),

    scores() {
      let scores = [];
      this.team_members.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      let data = {};

      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    }
  },
  components: {
    Card,
    ServerTable,
    LTable,
    ChartCard,
    StatsCard,
    TeamTask
  },
  mounted() {
    this.get_team_details();
    // this.scores();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // Get All Team Details And Members
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.$route.params.id);
    },
    refresh(query) {
      this.$store
        // .dispatch("user/userTask", { id: this.mainUserId, query: query })
        // .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        // });
      // this.$store.dispatch("user/handlerHowToUseYourPatterns");
      this.$store.dispatch("topics/handlerStandardTopics");
    },
    // Delete Member From Team
    delete_member_from_team(teamId, memberId) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_member_from_team", {
              team_id: teamId,
              member_id: memberId
            })
            .then(_ => {
              this.get_team_details();
              // ["user/get_team_members"]
              // this.$store.getters["user/get_team_members"];
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    // Delete Member From Team
    delete_invited_member_team(teamId, email) {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_invited_member_from_team", {
              team_id: teamId,
              email: email
            })
            .then(_ => {
              this.get_team_details();
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },

    // Open Add Statements
    open_add_statement_dialog(team_id, member_id, type, patternId, objectData) {
      this.team_id = team_id;
      this.member_id = member_id;
      this.type = type;
      this.pattern_id = patternId;
      this.show_add_statements_dialog = !this.show_add_statements_dialog;
      if (this.type == 2) {
        this.user.sequence_statement = objectData.sequence;
        this.user.confluence_statement = objectData.confluence;
        this.user.precision_statement = objectData.precision;
        this.user.technical_reasoning_statement =
          objectData.technical_reasoning;
      }
      console.log(objectData);
      // this.user = item;
    },
    // Close Add Statements
    close_add_statement_dialog() {
      this.team_id = null;
      this.member_id = null;
      this.show_add_statements_dialog = !this.show_add_statements_dialog;
    },
    // Add Statements
    saveStatements() {
      this.$refs["personalStatementsRef"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("user/add_team_statement_for_member", {
                team_id: this.team_id,
                user_id: this.member_id,
                sequence: this.user.sequence_statement,
                confluence: this.user.confluence_statement,
                precision: this.user.precision_statement,
                technical_reasoning: this.user.technical_reasoning_statement
              })
              .then(_ => {
                this.get_team_details();
                this.team_id = null;
                this.member_id = null;
                this.user.sequence_statement = null;
                this.user.confluence_statement = null;
                this.user.precision_statement = null;
                this.user.technical_reasoning_statement = null;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.show_add_statements_dialog = false;
              });
          } else {
            this.$store
              .dispatch("user/add_team_statement_for_member", {
                id: this.pattern_id,
                team_id: this.team_id,
                user_id: this.member_id,
                sequence: this.user.sequence_statement,
                confluence: this.user.confluence_statement,
                precision: this.user.precision_statement,
                technical_reasoning: this.user.technical_reasoning_statement
              })
              .then(_ => {
                this.get_team_details();
                this.team_id = null;
                this.member_id = null;
                this.user.sequence_statement = null;
                this.user.confluence_statement = null;
                this.user.precision_statement = null;
                this.user.technical_reasoning_statement = null;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.show_add_statements_dialog = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  height: auto;
  cursor: pointer;
  line-height: 38px;
}
.team_routes_wrapper {
  display: flex;
  gap: 10px;
  padding: 20px 15px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  .back_link {
    color: #777;
    background-color: #eee;
    padding: 8px 10px;
    display: block;
    width: fit-content;
    margin: 10px 0;
    border-radius: 8px;
    transition: 0.3s;
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
}
// Start Collapse
.wrapper_collapse_for_header {
  display: flex;
  justify-content: space-between;
  width: 95%;
  .head_part_one {
    .part_a {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #777;
      .user_icon {
        &.admin_badge {
          color: #3c3;
        }
      }
    }
    .part_b {
      color: #11bfe3;
    }
  }
  .head_part_two {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
// LCI Scores
.main-scores-container {
  // @media (min-width: 320px) and (max-width: 991px) {
  //   margin-top: 15px;
  // }
  padding-top: 20px;
  .every-score {
    gap: 7px;
    .score-word {
      color: #777;
    }
    .label {
      padding: 0.5rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      &.precision {
        background-color: #3c3;
      }

      &.technical_reasoning {
        background-color: #f93;
      }

      &.sequence {
        background-color: #39c;
      }

      &.confluence {
        background-color: #c36;
      }
    }
  }
}
.statements_wrapper {
  margin-top: 10px;
  // border-top: 1px solid #ccc;
  .just_for_wrapper_icon {
    // background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .part_two_icons {
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .all_statements {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    border-bottom: 1px solid #ccc;
    .every_statement {
      // margin-top: 15px;
      padding: 0.6rem 0.5rem;
      .state_name {
        font-size: 18px;
        // margin-bottom: 8px;
        &.sec {
          color: #11bfe3;
        }
        &.per {
          color: #3c3;
        }
        &.tec {
          color: #f93;
        }
        &.con {
          color: #c36;
        }
      }
      .state_words {
        color: #777;
        line-height: 1.7;
        font-size: 16px;
      }
    }
  }
  .without_statement {
    // margin-top: 20px;
    font-weight: bold;
    .st_words {
      padding: 5px 0 0 0;
      color: #777;
      line-height: 1.7;
      text-align: center;
      font-size: 16px;
    }
  }
}
// Start Introduce Yourself
.introduce_yourself_wrapper {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  .every_intro {
    padding: 0.6rem 0.5rem;
    border-bottom: 1px solid #ccc;
    .question_title {
      font-size: 19px;
      color: #6e6e6e;
      margin-bottom: 8px;
    }
    .patterns_wrapper {
      list-style: none;
      .pattern_title {
        font-size: 18px;
        margin-bottom: 8px;
      }
      .intro_list {
        .every_item {
          color: #777;
          line-height: 1.7;
          font-size: 16px;
          margin-bottom: 8px;
        }
      }
      .for_avoid_etc_words {
        color: #777;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 8px;
        position: relative;
      }
    }
  }
}
.text_area_desc {
  color: #606266;
  width: 100%;
  border: 1px solid #c0c4cc;
  outline: none;
  transition: 0.3s;
  border-radius: 4px;
  font-size: inherit;
  outline: 0;
  padding: 0 15px;
  height: 150px !important;
  line-height: 1.7 !important;
  padding: 10px 15px;
  resize: none;
  word-break: break-word;

  &:focus {
    border-color: #11bfe3;
  }
  // &.error_input {
  //   border-color: #f56c6c;
  // }
}
.main_invitation_members_wrapper {
  .every_invitation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    .part_i {
      display: flex;
      gap: 5px;
      align-items: center;
      color: #777;
      .email_address {
        opacity: 0.5;
      }
      .email_icon {
        color: #11bfe3;
      }
    }
    .part_ii {
    }
  }
}
//// End Collapse
.team_details_wrapper {
  // Start Description Container
  .description_container {
    padding: 15px 0;
    color: #777;
    .decs_title {
      margin: 0;
    }
    .decs_text {
      margin-top: 15px;
      line-height: 1.7;
    }
  }
  // End Description Container
  .header_contact_wrapping {
    display: flex;
    justify-content: space-between;
    .title_icon {
      color: #11bfe3;
      margin-right: 5px;
    }
  }
  .actions-icons {
    font-size: 14px;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer;
    color: #777;
    transition: 0.3s;
    &.delete {
      &:hover {
        background-color: #fb404b;
        color: #fff;
      }
    }
    &.add {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  .main-members-container {
    padding: 0.5rem 0.3rem;
    margin-top: 0.7rem;
    position: relative;
    color: #777;
    .user-details {
      width: 200px;
      color: #777;
      .part-one {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .part-two {
        margin-top: 10px;
      }
    }
    .main-scores-container {
      @media (min-width: 320px) and (max-width: 991px) {
        margin-top: 15px;
      }
      .every-score {
        gap: 7px;
        .score-word {
          color: #777;
        }
        .label {
          padding: 0.5rem;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          &.precision {
            background-color: #3c3;
          }

          &.technical_reasoning {
            background-color: #f93;
          }

          &.sequence {
            background-color: #39c;
          }

          &.confluence {
            background-color: #c36;
          }
        }
      }
    }
    .td-actions {
      gap: 10px;
      @media (min-width: 320px) and (max-width: 991px) {
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
  }
}

.for_statements_details {
  .user_statement_info {
    .name_and_icon {
      display: flex;
      align-items: center;
      gap: 5px;
      .use_icon {
        color: #11bfe3;
        font-size: 18px;
      }
      .use_name {
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    .use_classification {
      margin-top: 8px;
      font-size: 18px;
    }
  }
  .statements_wrapper {
    .all_statements {
      .every_statement {
        margin-top: 15px;
        padding: 0.6rem 0.5rem;
        .state_name {
          font-size: 18px;
          margin-bottom: 8px;
          &.sec {
            color: #11bfe3;
          }
          &.per {
            color: #3c3;
          }
          &.tec {
            color: #f93;
          }
          &.con {
            color: #c36;
          }
        }
        .state_words {
          color: #777;
          line-height: 1.7;
          font-size: 16px;
        }
      }
    }
    .without_statement {
      margin-top: 20px;
      .st_words {
        // padding: 5px 0;
        color: #777;
        line-height: 1.7;
        text-align: center;
        font-size: 16px;
      }
    }
  }
}

.the_main_wrapper_for_charts {
  background: #fff;
  padding-bottom: 1rem;
  .card-header {
    border-radius: 16px !important;
  }
  .card {
    margin-bottom: 0;
    border: unset;
  }
  .card-body {
    // border: transparent !important;
  }
  .description_team {
    background-color: #fff;
    padding: 0 0.5rem;
    text-align: center;
    .team_type {
      color: #777;
      margin: 0;
      line-height: 1.7;
      font-size: 17px;
    }
  }

  .is_chart_wrapper {
    position: relative;
  }
  .lines_in_chart {
    position: relative;
    bottom: 240px;
    padding: 0 5px;
    // background: #eee;
    &::before {
      position: absolute;
      content: "";
      width: 87%;
      left: 5%;
      bottom: 0;
      border: 1px dashed #11bfe3;
    }
    &.avoid {
      &::before {
        bottom: -50px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -105px;
        font-size: 12px;
      }
    }
    &.needed {
      bottom: 324px;
      &::before {
        bottom: -75px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -105px;
        font-size: 12px;
      }
    }
    &.first {
      bottom: 443px;
      &::before {
        display: none;
      }
      .is_text_chart {
        position: absolute;
        bottom: -130px;
        font-size: 12px;
      }
    }
  }
}
// Just For Showing Close Icon In Show Statements Dialog
::v-deep .el-dialog__close {
  // display: block !important;
  // cursor: pointer;
}
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
</style>

<style lang="scss">
.team_details_wrapper {
  .card .ct-label.ct-vertical.ct-start {
    width: 38px !important;
  }
  .for_line_graph_members_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0;
    flex-wrap: wrap;
    .every_bullet_wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 20px;
      .bullet {
        background: #eee;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.member_1 {
        .bullet {
          background-color: black;
        }
      }
      &.member_2 {
        .bullet {
          background-color: #fe9d52;
        }
      }
      &.member_3 {
        .bullet {
          background-color: #4198d7;
        }
      }
      &.member_4 {
        .bullet {
          background-color: brown;
        }
      }
      &.member_5 {
        .bullet {
          background-color: #7a61ba;
        }
      }
    }
  }
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 11px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 90% !important;
    }
  }
}

.el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
  background-color: #118fe3 !important;
  color: #fff !important;
}
.twp {
  background: url("../../../assets/new-theme/lci/background.png") no-repeat;
  background-size: 80%;
  background-position: 600px center;
  position: relative;
  .action-twp {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
  }

  .el-button {
    background: transparent;
  }
}

.el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

.el-tabs__nav-scroll{
  padding: 10px;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6;
  border-bottom-color: #DCDFE6;
  border-radius: 7px;
}
.team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}
</style>
